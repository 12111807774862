import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VMenu,
    {
      attrs: { "close-on-content-click": false, "offset-x": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                VBtn,
                _vm._g(
                  _vm._b(
                    {
                      staticStyle: { "text-transform": "none" },
                      attrs: { text: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.currentAccount.name) + "\n      "
                  ),
                  _c(VIcon, { attrs: { small: "", right: "" } }, [
                    _vm._v("\n        mdi-chevron-down\n      ")
                  ])
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        VCard,
        { attrs: { elevation: "1" } },
        [
          _vm._l(_vm.accountList, function(workspace, key) {
            return _c(
              VListGroup,
              {
                key: workspace[0].workspace_id,
                attrs: { "no-action": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function() {
                        return [
                          _c(
                            VListItemTitle,
                            { staticClass: "text-subtitle-2" },
                            [
                              _vm._v(
                                "\n          " + _vm._s(key) + "\n        "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _vm._l(workspace, function(account) {
                  return _c(
                    VListItem,
                    {
                      key: "account-" + account.account_id,
                      attrs: { to: "", link: "", dense: "" }
                    },
                    [
                      _c(
                        VListItemContent,
                        {
                          on: {
                            click: function($event) {
                              return _vm.setAccount(
                                account.account_id,
                                account.workspace_id
                              )
                            }
                          }
                        },
                        [
                          _c(
                            VListItemTitle,
                            { staticClass: "text-caption" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(account.account_name) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          }),
          _vm._v(" "),
          _c(VDivider),
          _vm._v(" "),
          _c(
            VCardActions,
            [
              _c(
                VListItem,
                { attrs: { dense: "" } },
                [
                  _c(
                    VRow,
                    { attrs: { algin: "center", justify: "end" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { text: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.linkTo("/settings/line_coordination")
                            }
                          }
                        },
                        [
                          _c(
                            VIcon,
                            { attrs: { left: "", color: "grey lighten-1" } },
                            [
                              _vm._v(
                                "\n              mdi-cog-outline\n            "
                              )
                            ]
                          ),
                          _vm._v(
                            "\n            LINEアカウント連携設定\n          "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }