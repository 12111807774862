var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column is-4 text-preview" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "align-center" }, [
      _c("i", { staticClass: "material-icons i-people" }, [
        _vm._v("people_outline")
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "preview-img",
        attrs: { src: _vm.preview_img }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "preview-textbox" }, [
        _c("p", { staticClass: "preview-text" }, [
          _vm._v("\n        " + _vm._s(_vm.location_name) + " "),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.address) + "\n      ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "preview-textborder" }),
        _vm._v(" "),
        _c("div", { staticClass: "preview-location" }, [
          _vm._v("\n        Location\n      ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "preview-arrow" }, [
          _vm._v("\n        <\n      ")
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "space-between" }, [
      _c("div", { staticClass: "flex-start align-center" }, [
        _c("i", { staticClass: "material-icons i-navigate" }, [
          _vm._v("navigate_before")
        ]),
        _vm._v(" "),
        _c("i", { staticClass: "material-icons i-local-police md-light" }, [
          _vm._v("local_police")
        ]),
        _vm._v("TSUNAGARU V3\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "space-around align-center" }, [
        _c("i", { staticClass: "material-icons i-right i-access_time" }, [
          _vm._v("access_time")
        ]),
        _vm._v(" "),
        _c("i", { staticClass: "material-icons i-right i-assignment" }, [
          _vm._v("assignment")
        ]),
        _vm._v(" "),
        _c("i", { staticClass: "material-icons i-right i-dehaze" }, [
          _vm._v("dehaze")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }