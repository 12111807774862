import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "pagination" },
      [
        _c(
          VIcon,
          {
            staticClass: "previous",
            attrs: { color: "#333333", disabled: !_vm.getPriviousLinkStatus },
            on: {
              click: function($event) {
                return _vm.pageSelected(1)
              }
            }
          },
          [_vm._v("\n      keyboard_double_arrow_left\n    ")]
        ),
        _vm._v(" "),
        _c(
          VIcon,
          {
            staticClass: "previous",
            attrs: { color: "#333333", disabled: !_vm.getPriviousLinkStatus },
            on: {
              click: function($event) {
                return _vm.pageSelected(_vm.currentPage - 1)
              }
            }
          },
          [_vm._v("\n      keyboard_arrow_left\n    ")]
        ),
        _vm._v(" "),
        _vm._l(_vm.getDispPages, function(page) {
          return _c("div", { key: page }, [
            _vm.currentPage !== page
              ? _c(
                  "span",
                  {
                    staticClass: "page has-text-weight-bold",
                    on: {
                      click: function($event) {
                        return _vm.pageSelected(page)
                      }
                    }
                  },
                  [_vm._v(_vm._s(page))]
                )
              : _c("div", { staticClass: "page-disabled" }, [
                  _vm._v("\n        " + _vm._s(page) + "\n      ")
                ])
          ])
        }),
        _vm._v(" "),
        _c(
          VIcon,
          {
            staticClass: "next",
            attrs: { color: "#333333", disabled: !_vm.getNextLinkStatus },
            on: {
              click: function($event) {
                return _vm.pageSelected(_vm.currentPage + 1)
              }
            }
          },
          [_vm._v("\n      keyboard_arrow_right\n    ")]
        ),
        _vm._v(" "),
        _c(
          VIcon,
          {
            staticClass: "next",
            attrs: { color: "#333333", disabled: !_vm.getNextLinkStatus },
            on: {
              click: function($event) {
                return _vm.pageSelected(_vm.totalPageCount)
              }
            }
          },
          [_vm._v("\n      keyboard_double_arrow_right\n    ")]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }