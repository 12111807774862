import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VSnackbar,
    {
      staticClass: "mb-6 ml-6",
      attrs: {
        color: "white",
        text: "",
        top: "",
        center: "",
        timeout: _vm.permanent || _vm.type === "error" ? -1 : 5000,
        elevation: "6"
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function(ref) {
            var attrs = ref.attrs
            return [
              _c(
                VBtn,
                _vm._b(
                  {
                    attrs: { text: "", icon: "", color: "grey-600" },
                    on: {
                      click: function($event) {
                        return _vm.handleClose()
                      }
                    }
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [
                  _c(VIcon, { attrs: { small: "" } }, [
                    _vm._v("\n        mdi-close\n      ")
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.displaySnackbar,
        callback: function($$v) {
          _vm.displaySnackbar = $$v
        },
        expression: "displaySnackbar"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "d-inline-flex align-center",
          staticStyle: { gap: "12px" }
        },
        [
          _c(
            "span",
            { class: _vm.iconAreaClass },
            [
              _c(VIcon, { attrs: { color: _vm.type, size: "20" } }, [
                _vm._v("\n        " + _vm._s(_vm.icon) + "\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("span", {
            staticClass: "text-subtitle-2 black--text",
            domProps: { innerHTML: _vm._s(_vm.text) }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }