var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logo" }, [
    _c(
      "svg",
      {
        attrs: {
          width: "461",
          height: "60",
          viewBox: "0 0 461 60",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }
      },
      [
        _c("g", { attrs: { "clip-path": "url(#clip0_402_2647)" } }, [
          _c("path", {
            attrs: {
              d:
                "M26 54.9306C26 58.0506 23.12 59.7106 20.24 59.7106C15.96 59.7106 14.56 56.8206 14.56 55.0806V11.3106H5.76C2.39 11.3106 0 9.31057 0 6.31057C0 2.98057 3.54 1.31057 5.76 1.31057H34.84C35.5674 1.27159 36.2953 1.37831 36.981 1.62446C37.6666 1.87061 38.2962 2.25124 38.8327 2.744C39.3693 3.23676 39.802 3.83172 40.1055 4.49398C40.4089 5.15624 40.5771 5.87246 40.6 6.60058C40.6 10.0106 37.15 11.3106 34.84 11.3106H26V54.9306Z",
              fill: "#040000"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M131.151 4.79172C131.151 1.67172 134.151 0.0117188 136.911 0.0117188C140.911 0.0117188 142.591 2.47172 142.591 4.65172V36.7417C142.591 53.1217 134.071 59.7417 121.071 59.7417C110.691 59.7417 99.7109 54.8917 99.7109 36.7417V4.65172C99.7109 1.38172 102.921 0.0117188 105.391 0.0117188C109.751 0.0117188 111.151 3.01172 111.151 4.79172V36.7417C111.151 47.6117 116.461 49.8617 121.231 49.8617C124.611 49.8617 131.151 48.4817 131.151 36.7417V4.79172Z",
              fill: "#040000"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M448.69 4.79172C448.69 1.67172 451.69 0.0117188 454.46 0.0117188C458.41 0.0117188 460.14 2.47172 460.14 4.65172V36.7417C460.14 53.1217 451.62 59.7417 438.61 59.7417C428.24 59.7417 417.25 54.8917 417.25 36.7417V4.65172C417.25 1.38172 420.46 0.0117188 422.93 0.0117188C427.29 0.0117188 428.69 3.01172 428.69 4.79172V36.7417C428.69 47.6117 434 49.8617 438.78 49.8617C442.15 49.8617 448.69 48.4817 448.69 36.7417V4.79172Z",
              fill: "#040000"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M165.109 23.7617V54.9317C165.109 56.6717 163.799 59.7117 159.429 59.7117C156.799 59.7117 153.839 58.2617 153.839 55.0817V8.41173C153.839 6.41173 152.989 0.0117188 159.309 0.0117188C164.919 0.0117188 166.419 4.48173 170.609 10.7217C172.229 13.1317 189.699 37.0517 189.699 37.0517V4.79172C189.699 3.12172 191.009 0.0117188 195.379 0.0117188C198.009 0.0117188 200.979 1.46172 200.979 4.65172V51.3117C200.979 53.2617 201.249 59.7117 195.589 59.7117C190.729 59.7117 187.589 53.9817 183.109 47.9217C180.889 44.8617 165.109 23.7617 165.109 23.7617Z",
              fill: "#040000"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M239.492 44.7117H225.002L221.482 56.0217C221.104 57.1637 220.356 58.1469 219.356 58.8154C218.356 59.4838 217.161 59.7993 215.962 59.7117C212.502 59.7117 209.872 57.9017 209.872 54.7917C209.859 53.9904 210.03 53.1969 210.372 52.4717L223.002 5.95172C223.822 4.14172 226.052 0.0117188 230.822 0.0117188C234.122 0.0117188 237.332 1.31172 239.302 5.95172L253.002 52.4717C253.341 53.123 253.539 53.8387 253.582 54.5717C253.582 55.0817 253.422 59.7917 247.162 59.7917C245.996 59.8084 244.855 59.4514 243.907 58.773C242.959 58.0946 242.253 57.1305 241.892 56.0217L239.492 44.7117ZM226.682 35.0117H237.792L231.152 12.7617L226.682 35.0117Z",
              fill: "#040000"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M342.871 44.7117H328.361L324.831 56.0217C324.456 57.1629 323.709 58.1458 322.711 58.8144C321.713 59.4829 320.52 59.7988 319.321 59.7117C315.861 59.7117 313.231 57.9017 313.231 54.7917C313.217 53.9912 313.384 53.1979 313.721 52.4717L326.381 5.95172C327.201 4.14172 329.381 0.0117188 334.201 0.0117188C337.491 0.0117188 340.701 1.31172 342.671 5.95172L356.341 52.4717C356.672 53.1258 356.866 53.8403 356.911 54.5717C356.911 55.0817 356.751 59.7917 350.491 59.7917C349.326 59.8068 348.186 59.4492 347.238 58.771C346.29 58.0929 345.583 57.1296 345.221 56.0217L342.871 44.7117ZM330.061 35.0117H341.171L334.531 12.7617L330.061 35.0117Z",
              fill: "#040000"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M298.921 24.79C302.041 24.79 307.921 26.31 307.921 33.12V40.51C307.921 55.95 296.451 59.79 285.581 59.79C265.171 59.79 257.391 46.31 257.391 30.79C257.391 15.79 262.671 0 282.761 0C297.331 0 306.701 7.61 306.701 13C306.697 13.6849 306.557 14.3622 306.289 14.9926C306.021 15.623 305.631 16.1939 305.141 16.6721C304.65 17.1503 304.07 17.5263 303.433 17.7783C302.796 18.0303 302.115 18.1533 301.431 18.14C300.201 18.14 298.881 18.07 295.431 14.96C291.151 11.19 289.191 9.81 283.591 9.81C269.841 9.81 269.261 20.54 269.261 30.39C269.261 41.77 273.741 49.45 286.091 49.45C299.011 49.45 297.651 41.91 297.651 40.39V36.39C297.651 35.16 296.831 34.51 295.511 34.51H288.621C287.241 34.526 285.91 33.9938 284.922 33.0301C283.934 32.0664 283.369 30.7501 283.351 29.37C283.354 28.7429 283.484 28.123 283.732 27.5472C283.98 26.9713 284.342 26.4514 284.796 26.0184C285.249 25.5854 285.785 25.2481 286.372 25.0267C286.959 24.8054 287.584 24.7045 288.211 24.73L298.921 24.79Z",
              fill: "#040000"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M377.22 54.9306C377.22 58.0506 374.33 59.7106 371.37 59.7106C367.17 59.7106 365.77 56.8906 365.77 55.0806V10.2206C365.77 4.43055 368.57 1.31055 375.57 1.31055H389.19C400.79 1.31055 408.19 8.20054 408.19 17.5405C408.19 30.0105 397.25 32.8305 394.11 33.6305C396.327 34.9519 398.215 36.761 399.63 38.9205L408.11 51.4505C408.916 52.5004 409.402 53.7609 409.51 55.0806C409.51 57.0306 407.51 59.7905 403.66 59.7905C400.78 59.7905 399.22 59.0605 397.9 56.7905L393.05 48.4505C390.33 43.8205 383.91 34.1105 378.8 34.1105H377.21L377.22 54.9306ZM386.55 24.5006C392.07 24.5006 396.43 23.2705 396.43 17.6105C396.43 12.6105 392.15 10.9505 387.21 10.9505H381.21C378.41 10.9505 377.26 11.2405 377.26 13.7805V24.5006H386.55Z",
              fill: "#040000"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M82.4116 12.0713L88.2016 6.28125C89.7122 7.82444 90.9025 9.65126 91.7041 11.6565C92.5056 13.6617 92.9024 15.8057 92.8718 17.9649C92.8411 20.1242 92.3835 22.256 91.5253 24.2377C90.6672 26.2193 89.4254 28.0116 87.8716 29.5112C80.6716 36.7012 69.2016 34.1913 61.4116 33.2713C57.5816 33.1213 51.0416 31.8313 46.5016 36.1413C45.0075 37.7291 44.1705 39.8242 44.1594 42.0044C44.1483 44.1847 44.9638 46.2882 46.4416 47.8913L40.8216 53.5213C35.3216 48.5213 33.6516 37.6312 40.9316 30.3512C48.7416 22.5412 60.1216 26.2912 68.4616 26.4512C76.2116 26.6612 79.2616 26.8712 83.1616 22.9712C87.0516 18.4812 83.7616 13.2313 82.4116 12.0713Z",
              fill: "#BCBCBC",
              stroke: "#BCBCBB",
              "stroke-width": "3",
              "stroke-miterlimit": "10"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M82.2486 11.9208C82.5971 12.2945 83.0202 12.5908 83.4905 12.7907C83.9607 12.9906 84.4677 13.0894 84.9786 13.081C85.4895 13.0725 85.993 12.9568 86.4564 12.7415C86.9197 12.5261 87.3327 12.2158 87.6686 11.8308C88.452 11.1114 88.9285 10.1181 88.9993 9.05683C89.07 7.99558 88.7296 6.94781 88.0486 6.13079C86.5054 4.62019 84.6786 3.42985 82.6734 2.62833C80.6682 1.82681 78.5242 1.42996 76.3649 1.46064C74.2057 1.49131 72.0738 1.9489 70.0922 2.80706C68.1106 3.66521 66.3183 4.90696 64.8186 6.46079C57.6286 13.6508 60.1386 25.1208 61.0586 32.9208C61.2086 36.7508 62.4986 43.2908 58.1886 47.8308C56.6008 49.3249 54.5057 50.1618 52.3254 50.173C50.1452 50.1841 48.0417 49.3686 46.4386 47.8908C46.4386 47.8908 43.5586 45.4308 40.9586 48.0308C38.3586 50.6308 40.8186 53.5108 40.8186 53.5108C45.8186 59.0108 56.6986 60.6808 63.9786 53.4008C71.7886 45.5908 68.0386 34.2108 67.8786 25.8708C67.6686 18.1208 67.4586 15.0708 71.3586 11.1708C75.8486 7.28078 81.0986 10.5708 82.2486 11.9208Z",
              fill: "#040000",
              stroke: "#040000",
              "stroke-width": "3",
              "stroke-miterlimit": "10"
            }
          })
        ]),
        _vm._v(" "),
        _c("defs", [
          _c("clipPath", { attrs: { id: "clip0_402_2647" } }, [
            _c("rect", {
              attrs: { width: "460.14", height: "59.7923", fill: "white" }
            })
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }