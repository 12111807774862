var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: "modal " + _vm.saveModalStatus }, [
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c("div", { class: "modal-content has-background-white" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "content has-text-centered" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  class:
                    "dropdown button_bgcolor_info mb-3 " +
                    _vm.deliveryDropDownStatus,
                  on: { click: _vm.deliveryDropDownClick }
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu",
                      attrs: { id: "dropdown-menu", role: "menu" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "dropdown-content has-text-left" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.createDelivery(
                                    _vm.MODE_CREATE_TEXT
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    テキスト\n                  "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c(
                "button",
                {
                  staticClass: "button button_bgcolor_light",
                  on: { click: _vm.backPage }
                },
                [_vm._v("\n              キャンペーン一覧に戻る\n            ")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block mt-6" }, [
      _c("h1", { staticClass: "has-text-centered" }, [
        _vm._v("\n              保存しました\n            ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dropdown-trigger" }, [
      _c(
        "button",
        {
          staticClass: "button button_bgcolor_info",
          attrs: { "aria-haspopup": "true", "aria-controls": "dropdown-menu" }
        },
        [
          _c("span", { staticClass: "has-text-white" }, [
            _vm._v("コンテンツを作成する")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "material-icons has-text-white ml-2" }, [
            _vm._v("arrow_drop_down")
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }