var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { on: { click: _vm.clearFlashMessage } }, [
    _c(
      "div",
      { class: "flash js-messagejs js-close-parent " + _vm.flashMessageStatus },
      [
        _c("div", { staticClass: "flash__inner" }, [
          _c("div", { staticClass: "flash__message" }, [
            Array.isArray(_vm.flashMessage)
              ? _c(
                  "ul",
                  { staticClass: "flash-messages-ul" },
                  _vm._l(_vm.flashMessage, function(message) {
                    return _c("li", { key: message }, [
                      _vm._v(
                        "\n            " + _vm._s(message) + "\n          "
                      )
                    ])
                  }),
                  0
                )
              : _c("ul", { staticClass: "flash-messages-ul" }, [
                  _c("li", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.flashMessage) +
                        "\n          "
                    )
                  ])
                ])
          ]),
          _vm._v(" "),
          _vm._m(0)
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flash__close-icon js-close-parent__trigger" },
      [
        _c("i", {
          staticClass: "v-icon notranslate mdi mdi-close theme--light",
          staticStyle: { "font-size": "16px" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }