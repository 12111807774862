var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: "modal " + _vm.draftModalStatus }, [
      _c(
        "div",
        {
          staticClass: "close_modal_draft",
          on: {
            click: function($event) {
              return _vm.setVisibility(false)
            }
          }
        },
        [_vm._v("\n      ×\n    ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-content modal_draft has-background-white" },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "content has-text-centered" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "block save_items has-text-left" }, [
                _vm._v(
                  '\n            "保存せずに戻る"ボタンを押すとこれまで編集した内容が削除されます。作成途中の場合は"保存して戻る"ボタンを押してコンテンツを下書き保存してください。\n          '
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-5" }, [
                _c("div", { staticClass: "buttons footer_item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button button_bgcolor_light",
                      on: {
                        click: function($event) {
                          return _vm.footerButtonClicked(false)
                        }
                      }
                    },
                    [_vm._v("\n                保存せずに戻る\n              ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button button_bgcolor_info has-text-white",
                      on: {
                        click: function($event) {
                          return _vm.footerButtonClicked(true)
                        }
                      }
                    },
                    [_vm._v("\n                保存して戻る\n              ")]
                  )
                ])
              ])
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block mt-5" }, [
      _c("h1", { staticClass: "has-text-centered" }, [
        _vm._v("\n              編集内容を下書き保存しますか？\n            ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }