var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: "modal " + _vm.incompleteModalStatus }, [
      _c(
        "div",
        {
          staticClass: "close_modal",
          on: {
            click: function($event) {
              return _vm.setVisibility(false)
            }
          }
        },
        [_vm._v("\n      ×\n    ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-content has-background-white" }, [
        _c("div", { staticClass: "content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "block incomplete_items has-text-left" }, [
            _c("div", [_vm._v("未入力項目")]),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(_vm.getEmptyItemList, function(item) {
                return _c("li", { key: item.key }, [
                  _c("a", [_vm._v(_vm._s(item.name))])
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "has-text-centered mb-3" }, [
            _c(
              "button",
              {
                staticClass:
                  "button button_bgcolor_info has-text-white incomplete_items",
                on: {
                  click: function($event) {
                    return _vm.footerButtonClicked(false)
                  }
                }
              },
              [_vm._v("\n            編集画面へ\n          ")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "has-text-centered mb-3" }, [
            _c(
              "button",
              {
                staticClass: "button button_bgcolor_light incomplete_items",
                on: {
                  click: function($event) {
                    return _vm.footerButtonClicked(true)
                  }
                }
              },
              [_vm._v("\n            無視して保存\n          ")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block mt-5" }, [
      _c("h1", { staticClass: "has-text-centered" }, [
        _vm._v("\n            未入力項目があります\n          ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }