import { VApp } from 'vuetify/lib/components/VApp';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VApp,
    [
      _c(
        "Layouts",
        [
          _c(
            "ErrorBoundary",
            { attrs: { "error-message": _vm.error.message } },
            [_c("router-view", _vm._b({}, "router-view", _vm.appProps, false))],
            1
          ),
          _vm._v(" "),
          _c("Snackbar", {
            attrs: {
              text: _vm.snackbars.text,
              type: _vm.snackbars.type,
              display: _vm.snackbars.display,
              permanent: _vm.snackbars.permanent,
              "handle-close": _vm.hideSnackbars
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }