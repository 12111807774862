import { VAlert } from 'vuetify/lib/components/VAlert';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2 mb-6" },
    [
      _vm.errorMessage
        ? _c(VAlert, { attrs: { type: "error", outlined: "" } }, [
            _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }