import { render, staticRenderFns } from "./layoutGlobalMenu.vue?vue&type=template&id=45db9b5e&"
import script from "./layoutGlobalMenu.vue?vue&type=script&lang=js&"
export * from "./layoutGlobalMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tsunagaru-v3-app-mintenant/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45db9b5e')) {
      api.createRecord('45db9b5e', component.options)
    } else {
      api.reload('45db9b5e', component.options)
    }
    module.hot.accept("./layoutGlobalMenu.vue?vue&type=template&id=45db9b5e&", function () {
      api.rerender('45db9b5e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/src/shared/components/layout/layoutGlobalMenu.vue"
export default component.exports