import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VList,
        { attrs: { dense: "", color: "#EEFAFA" } },
        [
          _c(
            VListItemGroup,
            {
              attrs: { color: "primary" },
              model: {
                value: _vm.selectedMainMenu,
                callback: function($$v) {
                  _vm.selectedMainMenu = $$v
                },
                expression: "selectedMainMenu"
              }
            },
            [
              _c(
                VListItem,
                {
                  attrs: { value: "deliveries" },
                  on: {
                    click: function($event) {
                      return _vm.linkTo("/deliveries")
                    }
                  }
                },
                [
                  _c(
                    VListItemIcon,
                    [_c(VIcon, [_vm._v("mdi-message-outline")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VListItemContent,
                    [_c(VListItemTitle, [_vm._v("配信")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VListItem,
                {
                  attrs: { value: "lists" },
                  on: {
                    click: function($event) {
                      return _vm.linkTo("/lists")
                    }
                  }
                },
                [
                  _c(
                    VListItemIcon,
                    [_c(VIcon, [_vm._v("mdi-account-outline")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VListItemContent,
                    [_c(VListItemTitle, [_vm._v("リスト")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VListItem,
                {
                  attrs: { value: "contents" },
                  on: {
                    click: function($event) {
                      return _vm.linkTo("/contents")
                    }
                  }
                },
                [
                  _c(
                    VListItemIcon,
                    [_c(VIcon, [_vm._v("mdi-folder-multiple-image")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VListItemContent,
                    [_c(VListItemTitle, [_vm._v("コンテンツ")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VListItem,
                {
                  attrs: { value: "campaigns" },
                  on: {
                    click: function($event) {
                      return _vm.linkTo("/campaigns")
                    }
                  }
                },
                [
                  _c(
                    VListItemIcon,
                    [_c(VIcon, [_vm._v("mdi-bullhorn-outline")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VListItemContent,
                    [_c(VListItemTitle, [_vm._v("キャンペーン")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VListGroup,
                {
                  attrs: {
                    value: _vm.selectedMainMenu === "analytics",
                    "no-action": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function() {
                        return [
                          _c(
                            VListItemIcon,
                            [_c(VIcon, [_vm._v("mdi-chart-box-outline")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VListItemContent,
                            [_c(VListItemTitle, [_vm._v("分析")])],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _vm._l(_vm.items, function(item) {
                    return _c(
                      VListItem,
                      {
                        key: item.text,
                        attrs: { to: item.link },
                        on: {
                          click: function($event) {
                            return _vm.linkTo(item.link)
                          }
                        }
                      },
                      [
                        _c(
                          VListItemContent,
                          [
                            _c(VListItemTitle, {
                              domProps: { textContent: _vm._s(item.text) }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                VListItem,
                {
                  attrs: { value: "settings" },
                  on: {
                    click: function($event) {
                      return _vm.linkTo("/settings/users")
                    }
                  }
                },
                [
                  _c(
                    VListItemIcon,
                    [_c(VIcon, [_vm._v("mdi-cog-outline")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VListItemContent,
                    [_c(VListItemTitle, [_vm._v("設定")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }