import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VFooter,
    { staticClass: "caption pb-6", attrs: { color: "white" } },
    [
      _c(
        VBtn,
        {
          attrs: {
            small: "",
            text: "",
            color: "grey darken-2",
            href: "https://help.tsunagaru.app/",
            target: "_blank"
          }
        },
        [_vm._v("\n    使い方\n  ")]
      ),
      _vm._v(" "),
      _c(
        VBtn,
        {
          attrs: {
            small: "",
            text: "",
            color: "grey darken-2",
            href: "https://www.tsunagaru.app/contact",
            target: "_blank"
          }
        },
        [_vm._v("\n    お問い合わせ\n  ")]
      ),
      _vm._v(" "),
      _c(
        VBtn,
        {
          attrs: {
            small: "",
            text: "",
            color: "grey darken-2",
            href: "https://www.tsunagaru.app/terms",
            target: "_blank"
          }
        },
        [_vm._v("\n    利用規約\n  ")]
      ),
      _vm._v(" "),
      _c(VSpacer),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("TsuIcon", { attrs: { size: "XS" } }, [
            _vm._v("\n      mdi-copyright\n    ")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "ml-1" }, [
            _vm._v("OPT, Inc. All Rights Reserved.")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }