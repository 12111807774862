var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: "modal " + _vm.updateContentsModalStatus }, [
      _c(
        "div",
        {
          staticClass: "close_modal",
          on: {
            click: function($event) {
              return _vm.setVisibility(false)
            }
          }
        },
        [_vm._v("\n      ×\n    ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-content update_contents has-background-white" },
        [
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "content has-text-centered" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "block has-text-left mx-6" }, [
                  _vm._v(
                    "\n            選択したコンテンツは既に他の配信で利用されています。上書き保存した場合、そのコンテンツを利用した配信はすべて更新されます。\n          "
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.updateContentsMode, function(content) {
                  return _c(
                    "div",
                    { key: content.index, staticClass: "mb-3" },
                    [
                      _c(
                        "button",
                        {
                          class: content.class,
                          on: {
                            click: function($event) {
                              return _vm.footerButtonClicked(content.mode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(content.text) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block mt-5" }, [
      _c("h1", { staticClass: "has-text-left mx-6" }, [
        _vm._v("\n              コンテンツを上書き保存"),
        _c("br"),
        _vm._v("しますか？\n            ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }