import { VMain } from 'vuetify/lib/components/VMain';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VMain,
    { staticClass: "grey lighten-4 mb-6" },
    [
      _c(
        VSheet,
        {
          staticClass: "d-flex flex-column justify-space-between",
          staticStyle: { "min-height": "96vh" },
          attrs: { color: "transparent" }
        },
        [_vm._t("default"), _vm._v(" "), _c("LayoutFooter")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }